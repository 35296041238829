import styled from 'styled-components';

export const Telamaxima = styled.div`
    max-width: 1024px;
    margin: auto;

    .parceirosUp{
        display: flex;
        flex-direction: ${window.innerWidth>675? 'row': 'column'};
        align-items: center;
        justify-content: center;
    }
    .duasCol{
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .tresCol{
        display: flex;
        flex-direction: ${window.innerWidth>675? 'row': 'column'};
        margin: auto;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .parceiro{
        display: flex;
        flex-direction: column;
        width: ${window.innerWidth>675? '250px': '70%'};
        height: ${window.innerWidth>675? 'auto': 'auto'};
        margin: auto;
        align-items: center;
        justify-content: center;

        img{
            width: 100%;
            height: auto;
            margin: auto;
            filter: grayscale(1);
        }
        img:hover{
            filter: grayscale(0);
        }
    }
`;