import React from 'react';
import { Container } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Formmensagem from '../Formmensagem';

const useStyles = makeStyles({
    root: {
      minHeight: 175,
      minWidth: window.innerWidth> 675? 'auto':275,
      flex: 1,
      margin: 'auto',
      marginBottom:'0',
      border: 0,
      backgroundColor: 'rgba(0,81,83,1)',
      color: '#ffffff',
      boxShadow: '0px 0px 0px #a0a0a0',
      '& svg':{
          margin: '1rem',
      },
      
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
      fontSize:'15px',
      textAlign:'left',
    },
   
    telaMaxima:{
        maxWidth: '1024px',
        margin:'auto',
        display: 'flex',
        flexDirection: window.innerWidth>675? 'row':'column',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
    },
    buttonC:{
        width: '90%',
        padding: '0.5rem',
        borderRadius: '4px',
        border:"2px solid rgba(255,255,255,0.8)",
        color:"rgba(255,255,255,0.8)",
        backgroundColor: 'rgba(0,73,81,1)',
        marginTop: '0.5rem',
        '&:hover':{
            backgroundColor: 'rgba(255,255,255,0.1)',
        }
    }
  });

function Footerinformacoes() {
    // const [focar, setFocar] = React.useState(false);
    const classes = useStyles();
    const [dialogo, setDialogo] = React.useState(false);

    return(
        <Container id="contato" fluid className="m-0" variant="dark" style={{backgroundColor:'rgba(0,81,83,1)',paddingTop: ' 4rem ',}}>
            <Typography  variant="h5" component="h5" style={{margin:'2rem 1rem', letterSpacing:'0.2rem', color:'rgba(355,355,355,0.9)'}}>
                INFORMAÇÕES DE CONTATO
            </Typography>
            <div className={classes.telaMaxima }>
         
            <Card className={classes.root} variant="outlined" style={{ margin:'0.5rem'}}>
                <CardContent>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15926.176693821164!2d-40.3532564!3d-3.6906067!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd86e8d65e8f3293!2sEng%20Alan%20Ara%C3%BAjo!5e0!3m2!1spt-BR!2sbr!4v1589572576052!5m2!1spt-BR!2sbr" 
                    width={window.innerWidth>675? "550" : '100%'} 
                    height="300" 
                    frameBorder="0" 
                    style={{border:0}}
                    allowFullscreen="" 
                    aria-hidden="false" 
                    tabIndex="0">                
                </iframe>
                </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined" style={{ margin:'0.5rem'}}>
                <CardContent>
                    <Typography className={classes.pos}>
                        <div style={{color:"#ffffff"}}>
                    Av. Lúcia Sabóia, 575, Sala 303, Centro <br/>
                    CEP: 62010-830, Sobral - Ceará<br/>
                    CNPJ: 25.315.224/0001-­13<br/>
                    Tel./Whatsapp: {window.innerWidth>675?'':<br/> }
                    (88) 99352-8826 / (88) 98863-0818 <br/>
                    Email: engenheiroalanaraujo@gmail.com<br/>
                    <hr style={{backgroundColor:'#fff',}}/>
                    <b>Horário De Funcionamento</b><br/>
                    Seg - Sex: 8h00 às 18h00<br/>
                    Sáb - Dom: Fechado
                        </div>
                    </Typography>
                    <button className={classes.buttonC} onClick={()=>setDialogo(true)}>Fale conosco</button>
                </CardContent>
            </Card>
              
            </div>
            {dialogo? <Formmensagem dialogo={dialogo} closeDialogo={()=>setDialogo(false)}/> :''}
        </Container>
    );
}

export default Footerinformacoes;