import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';


// Api para envio da mensagem
import api from '../../services/apiMessage';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(',/[0-9]/, /\d/, ')',' ', /\d/,' ', /\d/, /\d/,/\d/,/\d/, '-', /\d/, /\d/, /\d/,/\d/,]}
      placeholderChar={'\u2000'}
      // showMask
      required
    />
  );
}

export default function FormDialog(props) {
  var [open, setOpen] = React.useState(props.dialogo);
  var [dados,setDados] = React.useState({
      name:'',
      email:'',
      number:'',
      subject:'',
      message:'',
  });
  var [env,setEnv] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.closeDialogo();
  };

  const enviarForm=e=>{
      e.preventDefault();

    if(dados.subject){
      api.post('/fale-conosco', dados)
      .then(a=>{
          console.log(a);
          setEnv(true);
      })
      .catch(error=>{
        console.log(error.response);
      })
    }
      
  }



  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {!env?
         <form onSubmit={enviarForm}>
         <DialogTitle id="form-dialog-title"><b>Contato</b></DialogTitle>
         <DialogContent>
           <DialogContentText>
             {/* Seja um responsável técnico para provedores.
             Entre em contato conosco e se torne um parceiro. */}
           </DialogContentText>
           <TextField
             autoFocus
             margin="dense"
             id="name"
             label="Nome"
             type="text"
             variant='outlined'
             onChange={e=>setDados({...dados, name:e.target.value})}
             value={dados.name}
             required
             fullWidth
           />
            <TextField
                    required
                    id="telefone"
                    label="Telefone/Whatsapp"
                    //   defaultValue="Hello World"
                    variant="outlined"
                    value={dados.number}
                    onChange={e=>setDados({...dados, number:e.target.value})}
                    InputProps={{
                        inputComponent: TextMaskCustom,
                    }}
                    fullWidth
                    margin="dense"
                />
           <TextField
             margin="dense"
             id="email"
             label="Email"
             type="email"
             variant='outlined'
             onChange={e=>setDados({...dados, email:e.target.value})}
             value={dados.email}
             required
             fullWidth
           />
            <FormControl variant="outlined" required  fullWidth   margin="dense">
                    <InputLabel id="demo-simple-select-outlined-label">Assunto</InputLabel>
                    <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={dados.subject}
                    onChange={e=>setDados({...dados, subject:e.target.value})}
                    // className={classes.campoMenor}
                    label="Assunto"
                    
                    >
                    <MenuItem value={"Solicitar Orçamento"}>Solicitar Orçamento</MenuItem>
                    <MenuItem value={"Contratar Serviços"}>Contratar Serviços</MenuItem>
                    <MenuItem value={"Dúvida"}>Dúvida</MenuItem>
                    <MenuItem value={"Sugestões"}>Sugestões</MenuItem>
                    <MenuItem value={"Outro"}>Outro</MenuItem>
                    </Select>
                </FormControl>
           <TextField
             margin="dense"
             id="mensagem"
             label="Mensagem"
             multiline={true}
             rows={4}
             variant='outlined'
             onChange={e=>setDados({...dados, message:e.target.value})}
             value={dados.message}
             required
             fullWidth
           />
         </DialogContent>
         <DialogActions>
           <Button onClick={handleClose} color="primary" style={{color:'rgba(0,0,0,0.5)'}}>
             Cancelar
           </Button>
           <Button type='submit' color="primary" variant='outlined' style={{borderColor:'none',backgroundColor:'rgba(0,83,81,1)', color:'#fff'}}>
             Enviar
           </Button>
         </DialogActions>
         </form>
         :<>
        <DialogTitle id="form-dialog-title">Contato</DialogTitle>
         <DialogContent style={{textAlign:'center'}}>
             <img src='../files/images/image004.png' style={{maxWidth: '200px', margin:'auto'}}/>
           <DialogContentText>
             Agradecemos seu contato.<br/>
             Sua mensagem foi enviada com sucesso!<br/> Em breve entraremos em.
           </DialogContentText>
         </DialogContent>
         <DialogActions>
          <Button onClick={handleClose} color="primary" style={{color:'rgba(0,0,0,0.5)'}}>
             Fechar
           </Button>
           </DialogActions>
         </>}
      </Dialog>
    </div>
  );
}