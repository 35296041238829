import React from 'react';
import { Container } from 'react-bootstrap';

import {Conteudo, Cartao} from './styles';
import {Link, useParams} from 'react-router-dom';

import {DropzoneArea} from 'material-ui-dropzone';
import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import Backdrop from '../../components/Backdrop';
import Formmensagem from '../../components/Formmensagem';
import TextField from '@material-ui/core/TextField';

import Alertalogin from '../../components/Alertalogin';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';

// icones 
import EditIcon from '@material-ui/icons/Edit';


// estilos
import { makeStyles } from '@material-ui/core/styles';

// alert 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AlertTitle from '@material-ui/lab/AlertTitle';

// estilizando com classstyle 
const useStyles = makeStyles((theme) => ({
    dialogEstilo:{
        width:'100%'
    },
    root: {
      display:'flex',
      flexDirection:'column',
      flex:"1",
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      justifyItems:'center',
      margin:'1rem',
      width: window.innerWidth> 675? "50%":"100%",
'& p':{
    textAlign:'center',
    fontSize: '14px',
    width:'100%',
    margin:'0.5rem auto',
},
'& > *': {
//   margin: "0.25rem auto",
//   width: '25ch',
    width: '100%',
},
},
tabela:{
display:'flex',
flexDirection: window.innerWidth> 675? 'row':'column',
width:'100%'
},
infoCadastro:{
flex:"1",
display:'flex',
flexDirection:'column',
padding:'0.5rem',

'& h5':{
  textAlign:'center',
  fontWeight:'bold',
  fontSize:'16px'
},
'& p':{

  textAlign:'center',
  fontSize: '14px',
},
'& ul':{
  textAlign:'left',
  fontSize:'13px',
  marginLeft:'-1.3rem'
},
},
separador:{
fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
fontSize:'14px',
// borderBottom: '1px solid rgba(0,0,0,0.2)',
width:'87%',
fontWeight:'bold',
},
imagemCad:{
width:'60%',
margin:'0.4rem auto'
},
duasCol:{
    display:'flex',
    flexDirection: window.innerWidth>675? 'row':'column',
}
}));

// mascara do telefone 


function Resetarsenha(props) {
    const classes = useStyles();
    const {token} = useParams();
    const [files, setFiles] = React.useState([]);
    const [fileantigo, setFileantigo] = React.useState([]);
    const [linkfile, setLinkfile] = React.useState();
    const [linestring, setLinestring]= React.useState([]);
    const [distanciatotal, setDistanciatotal] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [showfile,setShowfile]= React.useState(false);
    const [loading,setLoading] = React.useState(false);

    const [visucadpass, setVisucadpass] =React.useState(false);
    const [visuconfpass, setVisuconfpass] =React.useState(false);
    
    const [dialogo, setDialogo] = React.useState(false);

    const [dadosUsuario, setDadosUsuario] = React.useState({
      id:'',
      token:''
    });
    const [newsenha, setNewsenha] = React.useState({
      password:'',
      password_confirmation:''
    });

      const [open2,setOpen2]=React.useState({
        status: false,
        type:'',
        message:'',
      })

    const handleChange= e=>{
        setFiles(e);
    }

    const verificarConta=e=>{
      setLoading(true);
        API.get(`resetpassword?resetToken=${token}`)
        .then(a=>{
            console.log("Info: ",a.data);
            setDadosUsuario(a.data);
            setLoading(false);
        }).catch(error=>{
            setOpen2({status:true,type:'error',message:error.response.data.error});
            console.log(error.response);
            setLoading(false);
        })
    }

    const resetarSenha=e=>{
        e.preventDefault();
        setLoading(true);

        if(newsenha.password==newsenha.password_confirmation){
          if(newsenha.password.length>3){
            API.put(`resetpassword/${dadosUsuario.id}?token=${dadosUsuario.token}`, newsenha)
            .then(a=>{
                console.log(a.data);
                setOpen2({status:true,type:'success',message:'Senha alterada com sucesso!'});
                setLoading(false);
            }).catch(error=>{
                setOpen2({status:true,type:'error',message:error.response.data.error});
                console.log(error.response);
                setLoading(false);
            })
                 
          }else{
            
            setLoading(false);
          }
        }else{
            
          setLoading(false);
        }

     
  }

    React.useEffect(e=>{
      if(!(dadosUsuario.id && dadosUsuario.token)) verificarConta();
    },[]);
    


  return(
    <Container fluid className="m-0 p-0" >
        <Conteudo>
          <div className="contido">
              <div className="titulodapage">
                    <h3>{"Redefinir Senha"}</h3>
                    {/* Nesta aplicação é possível efetuar conversão mútua entre arquivos .KML, .KMZ, .XLS e .XLSX. */}
              </div>
             
             <div className="subReparticao">
              
                   <br/>
                    {(dadosUsuario.id && dadosUsuario.token)  || open2.status?
                      (open2.status)?
                      <Alert 
                        // onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} 
                        severity={open2.type} 
                        style={{maxWidth:'650px', margin:'auto'}}
                      >
                        {open2.message}
                      </Alert>
                      :
                      <form onSubmit={resetarSenha} style={{display:'flex', flexDirection:'column'}}>
                           <TextField 
                              id="outlined-basic-senha" 
                              label="Nova senha" 
                              variant="outlined" 
                              margin="dense" 
                              type={visucadpass? "text":"password"}
                              required
                              value={newsenha.password}
                              onChange={e=>setNewsenha({...newsenha, password: e.target.value})}
                              style={{maxWidth:'200px', margin:'auto'}}
                              helperText={(newsenha.password.length>0 && newsenha.password.length<4)?'Senha deve conter, no mínimo, 4 digitos.':''}
                              helperText={(newsenha.password.length>0 && newsenha.password.length<4)?true:false}
                              InputProps={{
                                endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisucadpass(!visucadpass)}>{!visucadpass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                              }}
                            />
                            <TextField 
                              id="outlined-basic-senha-conf" 
                              label="Confirmar nova senha" 
                              variant="outlined" 
                              margin="dense" 
                              type={visuconfpass? "text":"password"}
                              required
                              error={(newsenha.password.length>0 && newsenha.password_confirmation.length>0 && newsenha.password!=newsenha.password_confirmation)? true:false}
                              helperText={(newsenha.password.length>0 && newsenha.password_confirmation.length>0 && newsenha.password!=newsenha.password_confirmation)? <small>Senhas não correspondem.</small>:''}
                              value={newsenha.password_confirmation}
                              onChange={e=>setNewsenha({...newsenha, password_confirmation: e.target.value})}
                              style={{maxWidth:'200px', margin:'0.7rem auto'}}
                              InputProps={{
                                endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisuconfpass(!visuconfpass)}>{!visuconfpass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                              }}
                            />

                            <Button variant="contained" color="primary" style={{margin:'auto'}} type='submit'>Atualizar senha</Button>
                      </form>
                    :
                    <>
                     Aguarde enquanto suas credenciais estão sendo verificadas.
                    <br/><br/>  
                    <CircularProgress color="secondary" />
                    <br/><br/>
                    </>}
                    
             </div>

            <br/>
          </div>

              {show?
                <Kmlparaxlsdialog
                tamanho="md"
                message={[linestring,distanciatotal]}
                negar={()=>setShow(false)}
              />:''}

        </Conteudo>

        {loading? <Backdrop/> :''}
        <Alertalogin/>
        {dialogo? <Formmensagem dialogo={dialogo} closeDialogo={()=>setDialogo(false)}/> :''}
          {/* <Snackbar open={open2.status} autoHideDuration={6000} onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}}>
            <Alert onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} severity={open2.type}>
              {open2.message}
            </Alert>
          </Snackbar> */}
    </Container>
  );
}

export default Resetarsenha;