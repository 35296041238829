import React, {Component} from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';

// estilos
import { Conteudo } from './styles';
// links 
import { Link } from 'react-router-dom';

import LinkIcon from '@material-ui/icons/Link';

import Usuarioinfo from '../Usuarioinfo';

class Navbarcampo extends Component{
    constructor(props){
        super(props);
        this.state = {
            corpoHead: 'fixed-top corpo',
            sizeimg: 'img01',
            expanded: false,
        };
    }

    handleScroll(){
        if (document.documentElement.scrollTop > 150){ //QUANDO O SCROLL PASSAR DOS 100px DO TOPO
            this.setState({sizeimg: 'img01s', corpoHead:'fixed-top corpos'});
        } else{
            this.setState({sizeimg: 'img01',corpoHead:'fixed-top corpo'});
        }
    }
    componentDidMount() {
        window.onscroll = () => this.handleScroll();
      }
    onMouse =e=>{
        this.setState({sizeimg: 'img01',corpoHead:'fixed-top corpo'});
    }
    outMouse=e=>{
      if (document.documentElement.scrollTop > 150){ //QUANDO O SCROLL PASSAR DOS 100px DO TOPO
        this.setState({sizeimg: 'img01s', corpoHead:'fixed-top corpos'});
      }        
    }

    fecharCol=e=>{
      if(window.innerWidth<675){ this.setState({...this.state, expanded: !this.state.expanded})}
    }

    render(){
        return (
            <Conteudo>
              <div style={{minHeight:'5px', backgroundColor:'rgba(0,83,81,1)', position:'fixed', top:'0px', width:'100%', zIndex:'10000'}}/>
          <Navbar collapseOnSelect expanded={this.state.expanded} expand="md" bg="light" variant="light" className={this.state.corpoHead} onMouseMove={this.onMouse} onMouseOut={this.outMouse}>
          <Link to="/"><img src='../files/images/image002.png' className={this.state.sizeimg} id="imglogo"/></Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="" onClick={()=>this.setState({...this.state, expanded: !this.state.expanded})}/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              {/* <Link to="/">Home</Link> */}
              <Link to="/home" onClick={()=>this.fecharCol()}>{"Home"}</Link>
              <NavDropdown title="Ferramentas" id="collasible-nav-dropdown" style={{marginTop:'-0.1rem'}} >
                  <Link to="/kml-para-excel" style={{margin:"auto", width:'100%'}} onClick={()=>this.fecharCol()}>{"KML <> EXCEL"}</Link><br/>
                  <Link to="/medir-cabos" style={{margin:"auto", width:'100%'}} onClick={()=>this.fecharCol()}>{"Medir Cabos"}</Link><br/>
                  <Link to="/ordenar-pontos" style={{margin:"auto", width:'100%'}} onClick={()=>this.fecharCol()}>{"Ordenar Pontos"}</Link><br/>
                  <Link to="/nome-das-ruas" style={{margin:"auto", width:'100%'}} onClick={()=>this.fecharCol()}>{"Nome das Ruas"}</Link><br/>
              </NavDropdown>
             
              <Link to="/#" onClick={()=>this.fecharCol()}>{"Links Úteis"}</Link>
              <a href="http://alanaraujo.eng.br" >
                <LinkIcon size={20} style={{marginRight:'2px', marginBottom:'3px'}}/>
                Alan Araújo Eng.
              </a>
              <div className="btnSecond">
                <Usuarioinfo setNecessariologin={e=>this.props.setNecessariologin(e)} necessariologin={this.props.necessariologin} tamanho={this.state.sizeimg} user={this.props.user} userLogin={e=>this.props.userLogin(e)} userLogoff={e=>this.props.userLogoff()} fecharmenu={()=>this.fecharCol()}/>
              </div>
              
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </Conteudo>
        );
    }
  
}

export default Navbarcampo;