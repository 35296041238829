import React, {Component} from 'react';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
  } from 'react-floating-button-menu';
  import Tooltip from '@material-ui/core/Tooltip';
//   import MdAdd from '@material-ui/icons/add';
//   import MdClose from '@material-ui/icons/clear';
// icones 
import {GoPlus} from 'react-icons/go';
import {MdClose} from 'react-icons/md';
import {GiHeartPlus} from 'react-icons/gi';
import {FaWhatsapp,FaInstagram, FaYoutube, FaFacebookF} from 'react-icons/fa';

import Imageicon1 from '../../files/images/icons/menusiteprincipal.png';

// import { Container } from './styles';

export default class Floatingactionbutton extends Component {
    constructor(props){
        super(props);
        this.state={
                isOpen: true,
        }
    }
  render(){
      return(
          <div style={{position: "fixed", right:0, bottom:0, margin:"1.5rem", zIndex:'1000'}}>
<FloatingMenu
    slideSpeed={500}
    direction="up"
    spacing={8}
    isOpen={this.state.isOpen}
  >
    <MainButton
      iconResting={<GoPlus size={35} color="white" />}
      iconActive={<MdClose size={35} color="white" />}
      style={{backgroundColor:"#f45b69"}}
      onClick={() => this.setState({ isOpen: !this.state.isOpen })}
      size={56}
    />
    
    <ChildButton
      icon={
        <Tooltip title="Facebook" placement="left">
        <a target="_blank" href="https://www.facebook.com/AlanRodriguesA" style={{backgroundColor:"#3b5998", padding:'0.7rem', borderRadius:'50%'}}>
            <FaFacebookF size={35} color="white" />
        </a>
        </Tooltip>}
      size={56}
    />
    <ChildButton
      icon={
        <Tooltip title="Youtube" placement="left">
        <a target="_blank" href="https://www.youtube.com/alanaraujoeng" style={{backgroundColor:"#bb0000", padding:'0.7rem', borderRadius:'50%'}}>
            <FaYoutube size={35} color="white" />
        </a>
        </Tooltip>}
      size={56}
    />
    <ChildButton
      icon={
        <Tooltip title="Instagram" placement="left">
        <a target="_blank" href="https://instagram.com/alanaraujoeng" style={{backgroundColor:"#E1306C", padding:'0.7rem', borderRadius:'50%'}}>
            <FaInstagram size={35} color="white" />
        </a>
        </Tooltip>}
      size={56}
    />
     <ChildButton
      icon={
        <Tooltip title="Whatsapp" placement="left">
        <a target="_blank" href="https://wa.me/5588993528826?text=Tenho%20interesse%20em%20solicitar%20or%C3%A7amento%20dos%20seus%20servi%C3%A7os" style={{backgroundColor:"#34af23", padding:'0.7rem', borderRadius:'50%'}}>
            <FaWhatsapp size={35} color="white" />
        </a>
        </Tooltip>}
      size={56}
    //   onClick={() => console.log('First button clicked')}
    />
    <ChildButton
      icon={
        <Tooltip title="Site Alan Araújo Eng." placement="left">
        <a target="_blank" href="http://www.alanaraujo.eng.br" style={{backgroundColor:"#fff", padding:'0.7rem', borderRadius:'50%'}}>
            <img src={Imageicon1} style={{width:'100%'}} color="white" />
        </a>
        </Tooltip>}
      size={56}
    />
  </FloatingMenu>
          </div>
      );
  }
}
