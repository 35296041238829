import styled from 'styled-components';

export const Conteudo = styled.div`

     .dropdown-menu{
        text-align: center;
        a{
            color: rgba(0,84,83,1) !important;
            border-bottom: 2px solid rgba(255,255,255,1) !important;
            font-weight: bold;
            opacity: 80%;
            text-decoration:none;
            padding:0.1rem;
            margin: 0.2rem auto !important;
            display: inline-block;
        }
        a:hover, a:active{
            opacity: 100%;
            border-bottom: 2px solid rgba(0,84,83,1) !important;
            transition: 0.5s;
        }
     }
     #collasible-nav-dropdown{
        padding:0.6rem;
     }
    
    a{
        color: rgba(0,84,83,1) !important;
        border-bottom: 2px solid rgba(255,255,255,1) !important;
        font-weight: bold;
        opacity: 80%;
        text-decoration:none;
        padding:0.5rem;
        margin: 0.2rem auto !important;
    }
    a:hover, a:active{
        opacity: 100%;
        border-bottom: 2px solid rgba(0,84,83,1) !important;
        transition: 0.5s;
    }
    .iconCustom{
        
           
    }
    .btnSecond{
    
    }
    

    .corpo{
        opacity: 1;
        /* background-color: rgba(0,84,83,1) !important; */
        background-color: rgba(255,255,255,1) !important;
        margin-top: 5px;
        /* transition: 1s; */
    }
    .corpos{
        /* background-color: rgba(0,84,83,1) !important; */
        background-color: rgba(255,255,255,1) !important;
        margin-top: 5px;
        opacity: 0.6;
        transition: 1s;
    }
    .img01{
        width: 300px !important;
        transition: 1s;
    }
    .img01s{
        width: 200px !important;
        transition: 1s;
    };
    
    @media (max-width: 768px) {
        .corpo,.corpos{
            opacity: 1;
            transition: 1s;
            margin-top: 5px;
        }
        .img01,.img01s{
            width: 200px !important;
        }
    }
`;
