import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import LockOpenIcon from '@material-ui/icons/LockOpen';

// checkbox 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// alert 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



//imagens
// import Image001 from './files/cadastrologin/image001.png';

const useStyles = makeStyles((theme) => ({
  dialogEstilo:{
      width:'100%'
  },
  root: {
    display:'flex',
    flexDirection:'column',
    flex:"1",
    width: window.innerWidth> 675? "50%":"100%",
    '& p':{
        textAlign:'center',
        fontSize: '14px',
        width:'100%',
        margin:'0.5rem auto',
    },
    '& > *': {
      margin: "0.25rem auto",
      width: '25ch',
    },
  },
  iconAviso:{
      fontSize:'120px',
      border:'2px dashed',
      borderRadius:'10px',
      padding:'.5rem',
      ['& svg']:{
          padding:'1rem',
          margin:'0.1rem'
      }
  }
  
}));


export default function Usuariocrud(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pagina, setPagina] = React.useState('login');


  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.fechardialogo();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.dialogEstilo}
      >
         <DialogTitle id="scroll-dialog-title" >Atenção</DialogTitle>
         <DialogContent >
           <DialogContentText
             id="scroll-dialog-description"
             ref={descriptionElementRef}
             tabIndex={-1}
           >
               <WarningIcon className={classes.iconAviso}/><br/>
               Faça LOGIN para ter acesso a todas as ferramentas do sistema.
           </DialogContentText>
         </DialogContent>
    
      </Dialog>
    </div>
  );
}
