import React from 'react';
import {Container} from 'react-bootstrap';
import { Conteudo } from './styles';
import {AiOutlineInstagram} from 'react-icons/ai';
import {FaWhatsapp, FaFacebookF} from 'react-icons/fa';

import Footerinformacoes from '../Footerinformacoes';
import Nossosservicos from '../Nossosservicos';
import Empresasdogrupo from '../Empresasdogrupo';

export default function Footer() {
  return (
    <Conteudo>
      <Nossosservicos/>
      <Empresasdogrupo/>
      <Footerinformacoes/>
    <Container fluid className="p-0" style={{backgroundColor: "rgba(0,81,83,1)"}} variant="dark">
        <div style={{paddingTop: '1rem', paddingBottom: '2rem', paddingLeft:'1rem', paddingRight: '1rem'}}>
            <div classHame="iconesList" style={{padding: '1rem'}}>
                <a href="https://instagram.com/alanaraujoeng" target="_blank" className="iconeLink"><AiOutlineInstagram color={'#fff'} size={30}/></a>
                <a href="https://wa.me/5588993528826?text=Tenho%20interesse%20em%20solicitar%20or%C3%A7amento%20dos%20seus%20servi%C3%A7os" target="_blank" className="iconeLink"><FaWhatsapp color={'#fff'} size={30}/></a>
                <a href="https://www.facebook.com/AlanRodriguesA" target="_blank" className="iconeLink"><FaFacebookF color={'#fff'} size={28}/></a>
            </div>
            <span>
                Av. Lúcia Sabóia, 575 . Sala 303, Centro . Sobral . Ceará . Brasil <br/>
                Fones  (88) 99352-8826 / (88) 98863-0818 <br/>
                © Ferramentas | Alan Araújo Engenharia de Telecomunicações <br/>
                <small>Desenvolvido por UX Software</small>
            </span>
        </div>
    </Container>
    </Conteudo>
  );
}
