import React from 'react';
import { Container } from 'react-bootstrap';

import {Conteudo, Cartao} from './styles';
import {Link, useParams} from 'react-router-dom';

import {DropzoneArea} from 'material-ui-dropzone';
import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import Backdrop from '../../components/Backdrop';
import Formmensagem from '../../components/Formmensagem';
import TextField from '@material-ui/core/TextField';

import Alertalogin from '../../components/Alertalogin';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';

// icones 
import EditIcon from '@material-ui/icons/Edit';


// estilos
import { makeStyles } from '@material-ui/core/styles';

// alert 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AlertTitle from '@material-ui/lab/AlertTitle';

// estilizando com classstyle 
const useStyles = makeStyles((theme) => ({
    dialogEstilo:{
        width:'100%'
    },
    root: {
      display:'flex',
      flexDirection:'column',
      flex:"1",
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      justifyItems:'center',
      margin:'1rem',
      width: window.innerWidth> 675? "50%":"100%",
'& p':{
    textAlign:'center',
    fontSize: '14px',
    width:'100%',
    margin:'0.5rem auto',
},
'& > *': {
//   margin: "0.25rem auto",
//   width: '25ch',
    width: '100%',
},
},
tabela:{
display:'flex',
flexDirection: window.innerWidth> 675? 'row':'column',
width:'100%'
},
infoCadastro:{
flex:"1",
display:'flex',
flexDirection:'column',
padding:'0.5rem',

'& h5':{
  textAlign:'center',
  fontWeight:'bold',
  fontSize:'16px'
},
'& p':{

  textAlign:'center',
  fontSize: '14px',
},
'& ul':{
  textAlign:'left',
  fontSize:'13px',
  marginLeft:'-1.3rem'
},
},
separador:{
fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
fontSize:'14px',
// borderBottom: '1px solid rgba(0,0,0,0.2)',
width:'87%',
fontWeight:'bold',
},
imagemCad:{
width:'60%',
margin:'0.4rem auto'
},
duasCol:{
    display:'flex',
    flexDirection: window.innerWidth>675? 'row':'column',
}
}));

// mascara do telefone 


function Termos(props) {
    const classes = useStyles();
    

  return(
    <Container fluid className="m-0 p-0" >
        <Conteudo>
          <div className="contido">
              <div className="titulodapage">
                    <h3>{"Termos de Uso"}</h3>
                    {/* Nesta aplicação é possível efetuar conversão mútua entre arquivos .KML, .KMZ, .XLS e .XLSX. */}
              </div>
             
             <div className="subReparticao">
              
                  <h2>1. Termos</h2> 
                    <p>Ao acessar ao site <a href='https://ferramentas.alanaraujo.eng.br'>Ferramentas Eng. Alan Araújo</a>, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>
                  <h2>2. Uso de Licença</h2> 
                    <p>É concedida permissão para baixar temporariamente uma cópia do conteúdo (informações ou software) do site Ferramentas Eng. Alan Araújo , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: </p>
                    <ol>
                      <li>modificar ou copiar o conteúdo;  </li> 
                      <li>usar o conteúdo para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);  </li> 
                      <li>tentar descompilar ou fazer engenharia reversa de qualquer software contido no site Ferramentas Eng. Alan Araújo;  </li> <li>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou  </li> 
                      <li>transferir o conteúdo para outra pessoa ou 'espelhe' o conteúdo em qualquer outro servidor.</li>
                    </ol> 
                    <p>É concedida, também, a permissão para baixar os arquivos convertidos pelo software desde que enviados pelo próprio usuário. </p>
                    <p>Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por Ferramentas Eng. Alan Araújo a qualquer momento. Ao encerrar a visualização desse conteúdo ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.</p>
                  <h2>3. Isenção de responsabilidade</h2> 
                    <ol> 
                      <li>Os materiais no site da Ferramentas Eng. Alan Araújo são fornecidos 'como estão'. Ferramentas Eng. Alan Araújo não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos. </li> 
                      <li>Além disso, o Ferramentas Eng. Alan Araújo não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade do uso dos materiais em seu site ou de outra forma relacionado a esses materiais ou em sites vinculados a este site.</li> 
                    </ol> 
                  <h2>4. Limitações</h2> 
                    <p>Em nenhum caso o Ferramentas Eng. Alan Araújo ou seus fornecedores serão responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em Ferramentas Eng. Alan Araújo, mesmo que Ferramentas Eng. Alan Araújo ou um representante autorizado da Ferramentas Eng. Alan Araújo tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.</p> 
                  <h3>Precisão dos materiais</h3> 
                    <p>Os materiais exibidos no site da Ferramentas Eng. Alan Araújo podem incluir erros técnicos, tipográficos ou fotográficos. Ferramentas Eng. Alan Araújo não garante que qualquer material em seu site seja preciso, completo ou atual. Ferramentas Eng. Alan Araújo pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. No entanto, Ferramentas Eng. Alan Araújo não se compromete a atualizar os materiais.</p> 
                  <h2>6. Links</h2> 
                    <p>O Ferramentas Eng. Alan Araújo não analisou todos os sites vinculados ao seu site e não é responsável pelo conteúdo de nenhum site vinculado. A inclusão de qualquer link não implica endosso por Ferramentas Eng. Alan Araújo do site. O uso de qualquer site vinculado é por conta e risco do usuário.</p> 
                  <h3>Modificações</h3> 
                    <p>O Ferramentas Eng. Alan Araújo pode revisar estes termos de serviço do site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado à versão atual desses termos de serviço.</p> <h3>Lei aplicável</h3> <p>Estes termos e condições são regidos e interpretados de acordo com as leis do Ferramentas Eng. Alan Araújo e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.</p>
                   
                    
             </div>

            <br/>
          </div>

        </Conteudo>

    </Container>
  );
}

export default Termos;