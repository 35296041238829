import React from 'react';
import { Container } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import {Telamaxima} from "./styles.js";
// imagem dos parceiros


function Homeparceiros() {
  return (
      <Container fluid style={{
          backgroundColor:'#ffffff',
        //   backgroundColor:'#efefef',
          marginTop:'-5rem',
          padding: ' 4rem 0rem',
          }} id="parceiros">
            <a href="#parceiros"><hr style={{height:'8px', width:'6rem', backgroundColor:'#000', borderRadius:'20px', opacity:'20%', margin:'0rem auto'}}/></a>
            <Typography  variant="h4" component="h4" style={{margin:'2rem 1rem', letterSpacing:'0.2rem', color:'rgba(0,83,81,1)'}}>
                EMPRESAS DO GRUPO
            </Typography>
            <Telamaxima>
                <div className="parceirosUp">
                    <div className="tresCol">
                        <div className="parceiro">
                            <img src='../files/images/Parceiros/parce001.png' />
                        </div>
                        <div className="parceiro">
                            <img src='../files/images/Parceiros/parce002.png' />
                        </div>     
                        <div className="parceiro">
                            <img src='../files/images/Parceiros/parce003.png' />
                        </div>
                        
                    </div>
                </div>
                {/* <div className="parceirosUp">
                    <div className="duasCol">
                        <div className="parceiro">
                            <img src={Parce005} />
                        </div>
                        <div className="parceiro">
                            <img src={Parce006} />
                        </div>     
                    </div>
                    <div className="duasCol">
                        <div className="parceiro">
                            <img src={Parce007} />
                        </div>
                        <div className="parceiro">
                            <img src={Parce008} />
                        </div>     
                    </div>
                  
                </div> */}
                

            </Telamaxima>

      </Container>
  );
}

export default Homeparceiros;