import styled from 'styled-components';

export const Conteudo = styled.div`
    margin:auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

    .contido{
        width: 100%;
        max-width: 1024px;
        border-radius: 5px;
        background-color: rgba(255,255,255,1);
        box-shadow: 0px 1px 5px rgba(0,0,0,0.2);

   
        padding: 1rem;

        .titulodapage{
            text-align: left;
            font-family: Arial, Helvetica, sans-serif;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding: 0.5rem;
            color: rgba(0,0,0,0.7);
        }
        a{
            text-decoration: none;
        }
    }

    .campodeup{
        font-size: 25px !important;
        color: rgba(0,83,81,1);
    }
    .campodeupicon{
        background-color: #ffffff;
        border-color: rgba(0,83,81,1);
        padding: 1rem;
        width: ${window.innerWidth>675? '500px': '100%'};
        height: auto;
        margin:auto;
        svg{
            color: rgba(0,83,81,1);
        }
    }

    .divisor{
        height: 1px;
        width: 100%;
        max-width: 1024px;
        border: 1px dashed rgba(0,0,0,0.3);
        border-image-slice: 10; 
    }
    .balaoNoticia{
        /* box-shadow: 0px 0px 4px rgba(0,0,0,0.2); */
        margin: auto ;
        margin-top: -1.5rem;
        max-width: 500px;
        /* border-radius: 0px; */
        background-color: rgba(0,83,81,1);
        cursor: default;
        :hover{
            background-color: rgba(0,95,95,1);
        }
    }
`;