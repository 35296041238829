import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/core/styles';

import {Link} from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import image01 from '../../files/images/messages/ondeestaosarquivos.png';

const useStyles = makeStyles((theme) => ({
  root:{
    maxWidth: window.innerWidth>675? '400px': '100%',
      ['& .MuiSnackbarContent-root']:{
        backgroundColor:'#fff',
      },
      ['& .MuiSnackbarContent-action']:{
        color: 'rgba(0,0,0,0.8)',
    
      },
      ['& .MuiSnackbarContent-message']:{
          color: 'rgba(0,0,0,0.7)',
          width:'100%'
      }
  },
  menuBalao:{
    width: '100%',
    textAlign:'right',
  },
  conteudoMessage:{
    display:'flex',
    flexDirection:'row',
    ['& h5']:{
      color:'rgba(0,83,81,1)',
      fontWeight:'bold',
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      // opacity:'90%',
    },
  }
  }));

  
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

export default function TransitionsSnackbar() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false,
    Transition: Fade,
    controle:false,
  });

  const handleClick = (Transition) => () => {
    setState({
      open: true,
      Transition,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  React.useEffect(e=>{
        if(state.controle==false){
            setTimeout(e=>{
                setState({...state,open:true, controle:true})
            }, 3000);
        }
  },[])

  return (
    <div>
      <Snackbar
        // autoHideDuration={6000}
        open={state.open}
        // onClose={handleClose}
        transitionDuration={3000}
        anchorOrigin={{vertical:'bottom',horizontal: window.innerWidth>675? 'left':'center' }}
        TransitionComponent={SlideTransition}
        key={state.Transition.name}
        // action={
        //     <React.Fragment>
             
        //     </React.Fragment>
        //   }
          className={classes.root}
          message={<div>
            <div className={classes.menuBalao}>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.conteudoMessage}>
                <img src={image01} style={{width:'40%', maxHeight: (window.innerHeight/2.5)}}/>
                <div>
                    <h5>O que acontece com os arquivos?</h5>
                    <label>Por questões de segurança, nenhum arquivo enviado neste site permanece salvo em nosso banco de dados.</label>
                      {/* <strong><Link to ="/">Saiba mais</Link></strong> */}
                </div>
            </div>
          </div>}
      />
    </div>
  );
}
