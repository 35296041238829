import React from 'react';
import  Messagegeral from '../Messagegeral';

function Gerais() {
  return(
      <div>
            <Messagegeral/>
      </div>
  );
}

export default Gerais;