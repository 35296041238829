import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// tabelas 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
// icones 
import SaveIcon from '@material-ui/icons/Save';

// alert 
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// api
import API from '../../services/api';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState(props.tamanho);

  const [loading,setLoading] = React.useState(false);
  const [finalFileName, setFinalFileName] = React.useState('');

  const [open2,setOpen2]=React.useState({
    status: false,
    type:'',
    message:'',
  })

  const [desabilitar,setDesabilitar] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.negar();
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{
           borderBottom:"1px solid rgba(0,0,0,0.1)",
           margin:"0.2rem 1rem",
           padding:'0.6rem 0.2rem'
          }}>Comprimentos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* {props.message.map((a,i)=>(
                <div key={i}>
                    <b>Nome:</b> {a.name} | <b>Distância:</b> {a.distance}
                </div>
            ))} */}
             <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                  <TableCell> <b>Nome</b> </TableCell>
                  <TableCell> <b>Distância (m)</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.message[0].map((a,i)=>(
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">{a.name}</TableCell>
                        <TableCell>{a.distance}</TableCell>
                      </TableRow>
                  ))}
                    <TableRow>
                        <TableCell component="th" scope="row"><b>Total</b></TableCell>
                        <TableCell>{props.message[1]}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
           </TableContainer>
          </DialogContentText>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.negar()} color="primary" style={{color:'rgba(0,83,81)'}}>
            Voltar
          </Button>
          {props.user?

              <Tooltip title={desabilitar?"Para efetuar novo download reenvie o documento":"Clique para fazer download"} placement="top-left">
              <span>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className={classes.button}
                disabled={desabilitar}
                // startIcon={<SaveIcon />}
                onClick={()=>{
                  setLoading(true);
                  // console.log(props.linkfile)
                  API.get(props.linkfile, {
                    'responseType': 'blob', headers: {
                    "Authorization": "Bearer " + props.user.token,
                    'content-type': 'application/octet-stream'
                  }}).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    // console.log('>', props.finalFileName.slice(0,-4)+"- MEDIDOR DE CABOS ALAN ARAUJO ENG.xlsx")
                    link.setAttribute('download', decodeURI(props.finalFileName.slice(0,-4)+"- MEDIDOR DE CABOS ALAN ARAUJO ENG.xlsx"));
                    document.body.appendChild(link);
                    link.click();
                    setDesabilitar(true);
                    ReactGA.event({
                      category: 'Medir cabos',
                      action: 'Download do arquivo de medida dos cabos'
                    });
                    
                  }).catch(error => {
                    console.log(error.response);
                   if(error.response){ 
                     if(error.response.status==401){
                      props.setNecessariologin(true)
                    }else{
                      if(error.response.status==404){
                        setOpen2({status:true,type:'error',message:"Arquivo não encontrado."});
                      }else{
                        if(typeof(error.response.data.error)=='string' ){
                          setOpen2({status:true,type:'error',message:error.response.data.error})
                        }else{
                          setOpen2({status:true,type:'error',message:"Erro indefinido. Entre em contato com o suporte."});
                      }}
                    }}
                   
                     
                    setLoading(false);
                   
                })
                }}
              >
                Download
              </Button>
              </span>
              </Tooltip>

          :''}
                <Snackbar open={open2.status} autoHideDuration={6000} onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}}>
            <Alert onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} severity={open2.type}>
              {open2.message}
            </Alert>
          </Snackbar>
         
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}