import React from 'react';
import { Container } from 'react-bootstrap';

import {Conteudo, Cartao} from './styles';
import { Link, useLocation, BrowserRouter as Router, useParams } from "react-router-dom";

import {DropzoneArea} from 'material-ui-dropzone';
import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import Backdrop from '../../components/Backdrop';
import Formmensagem from '../../components/Formmensagem';

import Alertalogin from '../../components/Alertalogin';
import CircularProgress from '@material-ui/core/CircularProgress';

// icones 
import EditIcon from '@material-ui/icons/Edit';


// estilos
import { makeStyles } from '@material-ui/core/styles';

// alert 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AlertTitle from '@material-ui/lab/AlertTitle';

import ReactGA from 'react-ga';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
// estilizando com classstyle 
const useStyles = makeStyles((theme) => ({
    dialogEstilo:{
        width:'100%'
    },
    root: {
      display:'flex',
      flexDirection:'column',
      flex:"1",
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      justifyItems:'center',
      margin:'1rem',
      width: window.innerWidth> 675? "50%":"100%",
'& p':{
    textAlign:'center',
    fontSize: '14px',
    width:'100%',
    margin:'0.5rem auto',
},
'& > *': {
//   margin: "0.25rem auto",
//   width: '25ch',
    width: '100%',
},
},
tabela:{
display:'flex',
flexDirection: window.innerWidth> 675? 'row':'column',
width:'100%'
},
infoCadastro:{
flex:"1",
display:'flex',
flexDirection:'column',
padding:'0.5rem',

'& h5':{
  textAlign:'center',
  fontWeight:'bold',
  fontSize:'16px'
},
'& p':{

  textAlign:'center',
  fontSize: '14px',
},
'& ul':{
  textAlign:'left',
  fontSize:'13px',
  marginLeft:'-1.3rem'
},
},
separador:{
fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
fontSize:'14px',
// borderBottom: '1px solid rgba(0,0,0,0.2)',
width:'87%',
fontWeight:'bold',
},
imagemCad:{
width:'60%',
margin:'0.4rem auto'
},
duasCol:{
    display:'flex',
    flexDirection: window.innerWidth>675? 'row':'column',
}
}));

// mascara do telefone 


function Derectlogin(props) {
    const classes = useStyles();
    const {token} = useParams();
    const [files, setFiles] = React.useState([]);
    const [fileantigo, setFileantigo] = React.useState([]);
    const [linkfile, setLinkfile] = React.useState();
    const [linestring, setLinestring]= React.useState([]);
    const [distanciatotal, setDistanciatotal] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [showfile,setShowfile]= React.useState(false);
    const [loading,setLoading] = React.useState(false);
    
    const [dialogo, setDialogo] = React.useState(false);

    const [dadosUsuario, setDadosUsuario] = React.useState('');

    let query = useQuery();

      const [open2,setOpen2]=React.useState({
        status: false,
        type:'',
        message:'',
      })

    const handleChange= e=>{
        setFiles(e);
    }

    const verificarConta=e=>{

      // console.log("QUERY: ", query.get("token"));
        
        API.post(`direct_session?token=${query.get("token")}`)
        .then(a=>{

            console.log(a.data);
            ReactGA.event({
              category: 'Usuário',
              action: 'Login'
            });
            props.userLogin(a.data);
            
            window.location.href = window.location.origin;
            setDadosUsuario(a.data);
            a.data.msg?setOpen2({status:true,type:'success',message:a.data.msg}): setOpen2({status:true,type:'success',message:<div style={{textAlign:'left'}}>Seja bem vindo(a) {a.data.name.toUpperCase()}, sua  sessão foi iniciada.</div>});
        }).catch(error=>{
            setOpen2({status:true,type:'error',message:error.response.data.error});
            console.log(error.response);
        })
    }

    React.useEffect(e=>{
      if(!dadosUsuario && query.get("token")) verificarConta();
    },[]);


    


  return(
    <Container fluid className="m-0 p-0" >
        <Conteudo>
          <div className="contido">
              <div className="titulodapage">
                    <h3>{"Sessão automática"}</h3>
                    {/* Nesta aplicação é possível efetuar conversão mútua entre arquivos .KML, .KMZ, .XLS e .XLSX. */}
              </div>
             
             <div className="subReparticao">
              
                   <br/>
                    {open2.status?
                    <Alert 
                      // onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} 
                      severity={open2.type} 
                      style={{maxWidth:'650px', margin:'auto'}}
                    >
                      {open2.message}
                    </Alert>
                    :
                    <>
                      Aguarde enquanto seu login está sendo validado.
                    <br/><br/>  
                    <CircularProgress color="secondary" />
                    <br/><br/>
                    </>}
                    
             </div>

            <br/>
          </div>

              {show?
                <Kmlparaxlsdialog
                tamanho="md"
                message={[linestring,distanciatotal]}
                negar={()=>setShow(false)}
              />:''}

        </Conteudo>

        {loading? <Backdrop/> :''}
        <Alertalogin/>
        {dialogo? <Formmensagem dialogo={dialogo} closeDialogo={()=>setDialogo(false)}/> :''}
          {/* <Snackbar open={open2.status} autoHideDuration={6000} onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}}>
            <Alert onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} severity={open2.type}>
              {open2.message}
            </Alert>
          </Snackbar> */}
    </Container>
  );
}

export default Derectlogin;