import React from 'react';
import { Container } from 'react-bootstrap';

import {Conteudo, Cartao} from './styles';
import {Link} from 'react-router-dom';

import {DropzoneArea} from 'material-ui-dropzone';
import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import API from '../../services/api';
import Kmlparaxlsdialog from '../../components/Kmlparaxlsdialog';
import Backdrop from '../../components/Backdrop';
import Formmensagem from '../../components/Formmensagem';

import SvgIcon from '@material-ui/core/SvgIcon';
import Logo1 from '../../files/images/icons/kmlparaexcel2.svg';
import Logo2 from '../../files/images/icons/medircabos.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import image1 from '../../files/images/pages/configuracoes_senha.jpg';

import Logocontact from '@material-ui/icons/Announcement';
import Alertalogin from '../../components/Alertalogin';

// icones 
import EditIcon from '@material-ui/icons/Edit';

// mascara 
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

// divisor 
import {Divider} from '@material-ui/core/';

// campo de texto 
import TextField from '@material-ui/core/TextField';

// estilos
import { makeStyles } from '@material-ui/core/styles';

// alert 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AlertTitle from '@material-ui/lab/AlertTitle';

// estilizando com classstyle 
const useStyles = makeStyles((theme) => ({
    dialogEstilo:{
        width:'100%'
    },
    root: {
      display:'flex',
      flexDirection:'column',
      flex:"1",
      alignContent:'center',
      alignItems:'center',
      justifyContent:'center',
      justifyItems:'center',
      margin:'1rem',
      width: window.innerWidth> 675? "50%":"100%",
'& p':{
    textAlign:'center',
    fontSize: '14px',
    width:'100%',
    margin:'0.5rem auto',
},
'& > *': {
//   margin: "0.25rem auto",
//   width: '25ch',
    width: '100%',
},
},
tabela:{
display:'flex',
flexDirection: window.innerWidth> 675? 'row':'column',
width:'100%'
},
infoCadastro:{
flex:"1",
display:'flex',
flexDirection:'column',
padding:'0.5rem',

'& h5':{
  textAlign:'center',
  fontWeight:'bold',
  fontSize:'16px'
},
'& p':{

  textAlign:'center',
  fontSize: '14px',
},
'& ul':{
  textAlign:'left',
  fontSize:'13px',
  marginLeft:'-1.3rem'
},
},
separador:{
fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
fontSize:'14px',
// borderBottom: '1px solid rgba(0,0,0,0.2)',
width:'87%',
fontWeight:'bold',
},
imagemCad:{
width:'60%',
margin:'0.4rem auto'
},
duasCol:{
    display:'flex',
    flexDirection: window.innerWidth>675? 'row':'column',
}
}));

// mascara do telefone 

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(',/[0-9]/, /\d/, ')',' ', /\d/,' ', /\d/, /\d/,/\d/,/\d/, '-', /\d/, /\d/, /\d/,/\d/,]}
        placeholderChar={'\u2000'}
        // showMask
        required
      />
    );
  }

function Configuracoes(props) {
    const classes = useStyles();
    const [files, setFiles] = React.useState([]);
    const [fileantigo, setFileantigo] = React.useState([]);
    const [linkfile, setLinkfile] = React.useState();
    const [linestring, setLinestring]= React.useState([]);
    const [distanciatotal, setDistanciatotal] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [showfile,setShowfile]= React.useState(false);
    const [loading,setLoading] = React.useState(false);
    
    const [dialogo, setDialogo] = React.useState(false);

    const [dadosCadastrar, setDadosCadastrar] = React.useState({
        id:'',
        email:'',
        name:'',
        number:'',
        city:'',
        state:'',
        organization:'',
      }); 
      const [dadosAtuais, setDadosAtuais] = React.useState({
        id:'',
        email:'',
        name:'',
        number:'',
        city:'',
        state:'',
        organization:'',
      });

      const [dadosSenha, setDadosSenha] = React.useState({
        old_password:'',
        new_password:'',
        new_password_confirmation:'',
      })

      const [open2,setOpen2]=React.useState({
        status: false,
        type:'',
        message:'',
      })

      const [visupass, setVisupass] =React.useState(false);
      const [visucadpass, setVisucadpass] =React.useState(false);
      const [visuconfpass, setVisuconfpass] =React.useState(false);

    const handleChange= e=>{
        setFiles(e);
    }

    const uploadArquivo=e=>{
      setLoading(true);
        const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + props.user.token,
            }
          };
          const data = new FormData();
          data.append('file', files[0]);

        API.post('kml-to-xls', data, config)
        .then(a=>{
            setLinkfile(a.data.url);
            if(a.data.lineString.length>0){setLinestring(a.data.lineString);setDistanciatotal(a.data.totalDistance);}else{setLinestring([]);setDistanciatotal('');}
            setLoading(false);
        }).catch(error=>{
            console.log(error.response);
            setLoading(false);
        })
    }

    React.useEffect(e=>{
        if(!dadosCadastrar.email && !dadosCadastrar.name && props.user.token){
          getCadastro();
        }
    },);


    const getCadastro=e=>{
      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + props.user.token,
        }
      };
            API.get(`user/`,config)
            .then(a=>{
              // console.log(a.data);
              setLoading(false);
              setDadosCadastrar(a.data);
              setDadosAtuais(a.data);
               
            }).catch(error=>{
              // setOpen2({status:true,type:'error',message:error.response.data.error});
              setLoading(false);
            })

    }
    const atualizarCadastro=e=>{
        e.preventDefault();
        setLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + props.user.token,
          }
        };
       
          if(dadosCadastrar.name.length>0){
              API.put(`user`,dadosCadastrar, config)
              .then(a=>{
                // console.log(a.data);
                // setLoading(false);
                getCadastro();
                setOpen2({status:true,type:'success',message:"Dados atualizados com sucesso"});
                 
              }).catch(error=>{
                setOpen2({status:true,type:'error',message:error.response.data.error});
                setLoading(false);
              })
          }else{
            setOpen2({status:true,type:'error',message:`O campo NOME é não pode ser vazio.`});
            setLoading(false);
          }
      }
      const atualizarSenha=e=>{
        e.preventDefault();
        setLoading(true);

        const config = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + props.user.token,
          }
        };
       
        if(dadosSenha.new_password_confirmation==dadosSenha.new_password){
          if(dadosSenha.new_password.length>3){
            API.put(`userpassword`,dadosSenha, config)
            .then(a=>{

              // getCadastro();
              setDadosSenha({
                old_password:'',
                new_password:'',
                new_password_confirmation:'',
              });
              
              setOpen2({status:true,type:'success',message:"Senha atualizada com sucesso. Será necessário realizar login novamente."});   
              setTimeout(e=>{
                props.setNecessariologin(true);
                props.userLogoff();
              },3000);        
            }).catch(error=>{
              setOpen2({status:true,type:'error',message:error.response.data.error});
              setLoading(false);
            })

          }else{
            setOpen2({status:true,type:'error',message:`Senha deve ter no mínimo 4 caracteres.`});
            setLoading(false);
          }
        }else{
          setOpen2({status:true,type:'error',message:`As senhas não correspondem.`});
          setLoading(false);
        }
        
      }


  return(
    <Container fluid className="m-0 p-0" >
        <Conteudo>
          <div className="contido">
              <div className="titulodapage">
                    <h3>{"Configurações"}</h3>
                    {/* Nesta aplicação é possível efetuar conversão mútua entre arquivos .KML, .KMZ, .XLS e .XLSX. */}
              </div>
             
             <div className="subReparticao">
                <div className="subTitulo">
                    Atualizar dados cadastrais <EditIcon fontSize='small' style={{marginBottom:'0.2rem'}}/>
                </div> 
                <div className={classes.tabela}>
                    <form className={classes.root} autoComplete="off" onSubmit={atualizarCadastro}>
                        <div className={classes.duasCol}>
                            <TextField 
                                id="outlined-basic-nome" 
                                label="Nome" 
                                variant="outlined" 
                                margin="dense" 
                                type="text"
                                required
                                value={dadosCadastrar.name}
                                onChange={e=>setDadosCadastrar({...dadosCadastrar, name: e.target.value})}
                                style={{flex:3, margin:'0.25rem 0.2rem'}}
                                disabled
                            />
                            <TextField 
                                id="outlined-basic-email" 
                                label="E-mail" 
                                variant="outlined" 
                                margin="dense" 
                                type="email"
                                required
                                value={dadosCadastrar.email}
                                onChange={e=>setDadosCadastrar({...dadosCadastrar, email: e.target.value})}
                                style={{flex:2, margin:'0.25rem 0.2rem'}}
                                disabled
                            />
                        </div>
                        
        
                            {/* <TextField 
                            id="outlined-basic-senha" 
                            label="Senha" 
                            variant="outlined" 
                            margin="dense" 
                            type="password"
                            required
                            value={dadosCadastrar.password}
                            onChange={e=>setDadosCadastrar({...dadosCadastrar, password: e.target.value})}
                            />
                            <TextField 
                            id="outlined-basic-senha-conf" 
                            label="Confirmar senha" 
                            variant="outlined" 
                            margin="dense" 
                            type="password"
                            required
                            error={(dadosCadastrar.password.length>0 && dadosCadastrar.password_confirmation.length>0 && dadosCadastrar.password!=dadosCadastrar.password_confirmation)? true:false}
                            helperText={(dadosCadastrar.password.length>0 && dadosCadastrar.password_confirmation.length>0 && dadosCadastrar.password!=dadosCadastrar.password_confirmation)? <small>Senhas não correspondem.</small>:''}
                            value={dadosCadastrar.password_confirmation}
                            onChange={e=>setDadosCadastrar({...dadosCadastrar, password_confirmation: e.target.value})}
                            /> */}
                    

                        <div className={classes.duasCol}>
                        <TextField 
                                id="outlined-basic-telefone" 
                                label="Telefone" 
                                variant="outlined" 
                                margin="dense" 
                                type="text"
                                InputProps={{
                                    inputComponent: TextMaskCustom,
                                }}
                                //required
                                value={dadosCadastrar.number}
                                onChange={e=>setDadosCadastrar({...dadosCadastrar, number: e.target.value})}
                                style={{flex:2, margin:'0.25rem 0.2rem'}}
                            />
                            <TextField 
                                id="outlined-basic-estado" 
                                label="Estado" 
                                variant="outlined" 
                                margin="dense" 
                                type="text"
                                //required
                                value={dadosCadastrar.state}
                                onChange={e=>setDadosCadastrar({...dadosCadastrar, state: e.target.value})}
                                style={{flex:2, margin:'0.25rem 0.2rem'}}
                            />
                            <TextField 
                                id="outlined-basic-cidade" 
                                label="Cidade" 
                                variant="outlined" 
                                margin="dense" 
                                type="text"
                                //required
                                value={dadosCadastrar.city}
                                onChange={e=>setDadosCadastrar({...dadosCadastrar, city: e.target.value})}
                                style={{flex:3, margin:'0.25rem 0.2rem'}}
                            />
                            
                        </div>
                            
                        <TextField 
                                id="outlined-basic-empresa" 
                                label="Empresa" 
                                variant="outlined" 
                                margin="dense" 
                                type="text"
                                //required
                                value={dadosCadastrar.organization}
                                onChange={e=>setDadosCadastrar({...dadosCadastrar, organization: e.target.value})}
                                style={{flex:1, margin:'0.25rem 0.5rem !important'}}
                            />
                            
                            <div style={{height:'0.2rem'}}/>
                        <div  style={{width:'100%', textAlign:'left'}}>
                            <Button variant="contained" color="primary" type='submit' disabled={dadosAtuais==dadosCadastrar? true:false}>Atualizar</Button>
                        </div>
                        <small style={{opacity:'60%'}}>* Campos obrigatórios.</small>
                    </form>
                     
                </div>
             </div>
{/* 
             <div className="subReparticao">
                <div className="subTitulo"/>
                <div className="subTitulo">
                    Alterar senha <EditIcon fontSize='small' style={{marginBottom:'0.2rem'}}/>
                </div> 
                <div className={classes.tabela}>
                    <form className={classes.root} autoComplete="off" onSubmit={atualizarSenha}>
                        <div className={classes.duasCol}>
                           <div style={{display:'flex', flex:'1', flexDirection:'column', justifyContent:'center', justifyItems:'center'}}>
                           <label style={{textAlign:'center', fontSize:'12px', opacity:'70%'}}>Jamais forneça sua senha ou demais informações de acesso para terceiros.</label>
                           <TextField 
                            id="outlined-basic-senha" 
                            label="Senha antiga" 
                            variant="outlined" 
                            margin="dense" 
                            type={visupass? "text":"password"}
                            required
                            value={dadosSenha.old_password}
                            onChange={e=>setDadosSenha({...dadosSenha, old_password: e.target.value})}
                            style={{width:'95%', margin:'0.25rem 0.2rem'}}
                            InputProps={{
                              endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisupass(!visupass)}>{!visupass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                            }}
                            />
                           <TextField 
                            id="outlined-basic-senha" 
                            label="Nova senha" 
                            variant="outlined" 
                            margin="dense" 
                            type={visucadpass? "text":"password"}
                            required
                            value={dadosSenha.new_password}
                            onChange={e=>setDadosSenha({...dadosSenha, new_password: e.target.value})}
                            style={{width:'95%', margin:'0.25rem 0.2rem'}}
                            InputProps={{
                              endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisucadpass(!visucadpass)}>{!visucadpass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                            }}
                            />
                            <TextField 
                            id="outlined-basic-senha-conf" 
                            label="Confirmar nova senha" 
                            variant="outlined" 
                            margin="dense" 
                            type={visuconfpass?"text":"password"}
                            required
                            error={(dadosSenha.new_password.length>0 && dadosSenha.new_password_confirmation.length>0 && dadosSenha.new_password!=dadosSenha.new_password_confirmation)? true:false}
                            helperText={(dadosSenha.new_password.length>0 && dadosSenha.new_password_confirmation.length>0 && dadosSenha.new_password!=dadosSenha.new_password_confirmation)? <small>Senhas não correspondem.</small>:''}
                            value={dadosSenha.new_password_confirmation}
                            onChange={e=>setDadosSenha({...dadosSenha, new_password_confirmation: e.target.value})}
                            style={{width:'95%', margin:'0.25rem 0.2rem'}}
                            // InputProps={{
                            //   endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisuconfpass(!visuconfpass)}>{!visuconfpass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                            // }}
                            />
                           </div>
                           <div style={{display:'flex', flex:'1.1', flexDirection:'column', justifyContent:'center', justifyItems:'center'}}>
                             <img src={image1} style={{maxWidth:'300px', margin:'auto'}} />
                           </div>
                        </div>
        
                            <div style={{height:'0.5rem'}}/>
                        <div  style={{width:'100%', textAlign:'left'}}>
                            <Button variant="contained" color="primary" type='submit'>Mudar Senha</Button>
                        </div>
                        <small  style={{opacity:'60%'}}>* Campos obrigatórios.</small>
                    </form>
                     
                </div>
             </div> */}

            <br/>
          </div>

              {show?
                <Kmlparaxlsdialog
                tamanho="md"
                message={[linestring,distanciatotal]}
                negar={()=>setShow(false)}
              />:''}

        </Conteudo>

        {loading? <Backdrop/> :''}
        <Alertalogin/>
        {dialogo? <Formmensagem dialogo={dialogo} closeDialogo={()=>setDialogo(false)}/> :''}
          <Snackbar open={open2.status} autoHideDuration={6000} onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}}>
            <Alert onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} severity={open2.type}>
              {open2.message}
            </Alert>
          </Snackbar>
    </Container>
  );
}

export default Configuracoes;