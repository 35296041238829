import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Divider} from '@material-ui/core/';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import LockOpenIcon from '@material-ui/icons/LockOpen';
import API from '../../services/api';
import Backdrop from '../Backdrop';

// checkbox 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// mascara 
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';


// alert 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AlertTitle from '@material-ui/lab/AlertTitle';

import ReactGA from 'react-ga';


function Alert(props) {
  return <MuiAlert elevation={6}  {...props} />;
}


//imagens
// import Image001 from './files/cadastrologin/image001.png';

const useStyles = makeStyles((theme) => ({
  dialogEstilo:{
      width:'100%'
  },
  root: {
    display:'flex',
    flexDirection:'column',
    flex:"1",
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
    justifyItems:'center',
    width: window.innerWidth> 675? "50%":"100%",
    '& p':{
        textAlign:'center',
        fontSize: '14px',
        width:'100%',
        margin:'0.5rem auto',
    },
    '& > *': {
      margin: "0.25rem auto",
      width: '25ch',
    },
  },
  tabela:{
    display:'flex',
    flexDirection: window.innerWidth> 675? 'row':'column',
    width:'100%'
  },
  infoCadastro:{
    flex:"1",
    display:'flex',
    flexDirection:'column',
    padding:'0.5rem',

    '& h5':{
      textAlign:'center',
      fontWeight:'bold',
      fontSize:'16px'
    },
    '& p':{

      textAlign:'center',
      fontSize: '14px',
    },
    '& ul':{
      textAlign:'left',
      fontSize:'13px',
      marginLeft:'-1.3rem'
    },
  },
  separador:{
    fontFamily:"'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    fontSize:'14px',
    // borderBottom: '1px solid rgba(0,0,0,0.2)',
    width:'87%',
    fontWeight:'bold',
  },
  imagemCad:{
    width:'60%',
    margin:'0.4rem auto'
  }
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(',/[0-9]/, /\d/, ')',' ', /\d/,' ', /\d/, /\d/,/\d/,/\d/, '-', /\d/, /\d/, /\d/,/\d/,]}
      placeholderChar={'\u2000'}
      // showMask
      // required
    />
  );
}


export default function Usuariocrud(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [vdc,setVdc] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [pagina, setPagina] = React.useState('login');
  const [visupass, setVisupass] =React.useState(false);
  const [visucadpass, setVisucadpass] =React.useState(false);
  const [visuconfpass, setVisuconfpass] =React.useState(false);
  const [dadosLogin, setDadosLogin] = React.useState({
    email:'',
    password:'',
  });
  const [dadosCadastrar, setDadosCadastrar] = React.useState({
    email:'',
    password:'',
    password_confirmation:'',
    name:'',
    number:'',
    city:'',
    state:'',
    organization:'',
    accept_terms:false,
  });
  const [dadosRecuperar, setDadosRecuperar] = React.useState({
    status:false,
    email:'',
  });

  const [open2,setOpen2]=React.useState({
    status: false,
    type:'',
    message:'',
  })

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.setNecessariologin(false);
    setOpen(false);
    props.fechardialogo();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const novoCadastro=e=>{
    e.preventDefault();
    setShowBackdrop(true);
    if(dadosCadastrar.password==dadosCadastrar.password_confirmation){
      if(dadosCadastrar.password.length>3){
          API.post(`users`,dadosCadastrar)
          .then(a=>{
            // console.log(a.data);
            ReactGA.event({
              category: 'Usuário',
              action: 'Cadastro'
            });
            setVdc(true);
            setPagina('login');
            setShowBackdrop(false);
            setDadosCadastrar({
              email:'',
              password:'',
              password_confirmation:'',
              name:'',
              number:'',
              city:'',
              state:'',
              organization:'',
              accept_terms:false,
            });
             
          }).catch(error=>{
            setOpen2({status:true,type:'error',message:error.response.data.error});
            setShowBackdrop(false);
          })
      }else{
        setOpen2({status:true,type:'error',message:`Senha deve possuir no mínimo 4 caracteres.`});
        setShowBackdrop(false);
      }
    }
  }
  const novoLogin=e=>{
    e.preventDefault();
    setShowBackdrop(true);
    API.post(`session`,dadosLogin)
    .then(a=>{
      // console.log(a.data);
      ReactGA.event({
        category: 'Usuário',
        action: 'Login'
      });
      props.userLogin(a.data);
      setShowBackdrop(false);
      handleClose();
      // console.log(a.data);

    }).catch(error=>{
      // console.log(error.response.data);
    setOpen2({status:true,type:'error',message:error.response.data.error=="Você ainda não foi verificado"? <>{error.response.data.error}. Para reenviar confirmação por e-mail, <b style={{cursor:'pointer'}} onClick={e=>reenviarConf()}>clique aqui</b></>:error.response.data.error});
      setShowBackdrop(false);
    })
    
  }

  const novoEsqueciasenha=e=>{
    e.preventDefault();
    setShowBackdrop(true);
    var temp ={
      email: dadosRecuperar.email
    }
    API.post(`forgotpassword`,temp)
    .then(a=>{
      // console.log(a.data);
      setDadosRecuperar({email:'',status:true});
      setShowBackdrop(false);
      ReactGA.event({
        category: 'Usuário',
        action: 'Esqueceu a senha'
      });

    }).catch(error=>{
      setOpen2({status:true,type:'error',message:error.response.data.error});
      setShowBackdrop(false);
    })
  }

  const reenviarConf =e=>{
    // e.preventDefault();
    setShowBackdrop(true);
    var temp ={
      email: dadosLogin.email
    }
    API.put(`resend_email_verification`,temp)
    .then(a=>{
      // console.log(a.data);
      setOpen2({status:true,type:'success',message:<>Mensagem de confirmação reenviada para <b>{a.data.name}</b>.</>});
      setShowBackdrop(false);

    }).catch(error=>{
      setOpen2({status:true,type:'error',message:error.response.data.error});
      setShowBackdrop(false);
      console.log("Deu erro: ", error.response);
    })
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.dialogEstilo}
      >
        {pagina=="login"?
        <>
        <DialogTitle id="scroll-dialog-title" >Faça Login</DialogTitle>
        {/* <Divider/> */}
        <DialogContent >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            
         <div className={classes.tabela}>
            <form className={classes.root}  autoComplete="off" onSubmit={novoLogin}>
              <div style={{margin:'0.5rem auto', padding:'0rem', textAlign:'center'}}>
                  <LockOpenIcon style={{ fontSize:'40px',backgroundColor:'rgba(0,0,0,0.1)', padding:'0.5rem', borderRadius:'50%'}}/>
              </div>
              
              {/* <p>Realize Login para ter acesso a todas as ferramentas da plataforma de forma <b>gratuita</b>. </p> */}
                <TextField 
                  id="outlined-basic-email" 
                  label="E-mail" 
                  variant="outlined" 
                  margin="dense" 
                  type="email"
                  value={dadosLogin.email}
                  onChange={e=>setDadosLogin({...dadosLogin, email: e.target.value})}
                  required
                />

                   <TextField 
                  id="outlined-basic-senha" 
                  label="Senha" 
                  variant="outlined" 
                  margin="dense" 
                  type={visupass? "text":"password"}
                  value={dadosLogin.password}
                  onChange={e=>setDadosLogin({...dadosLogin, password: e.target.value})}
                  InputProps={{
                    endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisupass(!visupass)}>{!visupass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                  }}
                  required
                />
                 <br/>
                <Button variant="contained" color="primary" style={{margin:'auto'}} type='submit'>Login</Button>
                <br/>
                <Divider/>
                <p style={{textDecoration:'underline', cursor:'pointer'}}  onClick={()=>setPagina('recuperar-senha')}><small>Esqueci minha senha</small></p>
            </form>
            <div className={classes.infoCadastro}>

              <p>Vantagens de ser cadastrado:</p>
              <ul>
                <li>Acesso total ao conteúdo do site de Ferramentas;</li>
                <li>Download ilimitado dos arquivos convertidos;</li>
                <li>Visualização ilimitada de tamanho dos cabos;</li>
                <li>Acesso exclusivo a atualizações.</li>
              </ul>
              <h5>Ainda não é cadastrado?</h5>
              <Button 
                variant="contained" 
                style={{margin:'0rem 1.5rem', }} 
                onClick={()=>setPagina('cadastro')}
                color="secondary"
              >Cadastrar-se</Button>
            </div>
         </div>
          
         {vdc?
          <div>
            <br/>
            <Alert 
              variant="filled" 
              severity="success" 
              style={{width:'100%', marginBottom:'1rem',}}
              onClose={() => {setVdc(false)}}
            >
              <b>Usuário cadastrado com sucesso!</b><br/>Confirme seu E-mail antes de realizar login.
            </Alert>
          </div>:''}
        
         {props.necessariologin?
              <div>
                <br/>
                <Alert severity="warning">
                  {/* <AlertTitle>Warning</AlertTitle> */}
                  Realize login para ter acesso às ferramentas da plataforma de forma <strong>gratuita</strong>.
                </Alert>
              </div>:''}

          </DialogContentText>
        </DialogContent>
        </>:''}
        {pagina=='cadastro'?
         <>
         <DialogTitle id="scroll-dialog-title" >Realize seu cadastro</DialogTitle>
         <DialogContent >
           <DialogContentText
             id="scroll-dialog-description"
             ref={descriptionElementRef}
             tabIndex={-1}
           >
          <div className={classes.tabela}>
             <form className={classes.root} autoComplete="off" onSubmit={novoCadastro}>
               
                  <label className={classes.separador}>Informações Gerais</label>
                  <Divider/>
                 <TextField 
                   id="outlined-basic-nome" 
                   label="Nome" 
                   variant="outlined" 
                   margin="dense" 
                   type="text"
                   required
                   value={dadosCadastrar.name}
                   onChange={e=>setDadosCadastrar({...dadosCadastrar, name: e.target.value})}
                 />
                 <TextField 
                   id="outlined-basic-email" 
                   label="E-mail" 
                   variant="outlined" 
                   margin="dense" 
                   type="email"
                   required
                   value={dadosCadastrar.email}
                   onChange={e=>setDadosCadastrar({...dadosCadastrar, email: e.target.value})}
                 />
 
                    <TextField 
                      id="outlined-basic-senha" 
                      label="Senha" 
                      variant="outlined" 
                      margin="dense" 
                      type={visucadpass? "text":"password"}
                      required
                      value={dadosCadastrar.password}
                      onChange={e=>setDadosCadastrar({...dadosCadastrar, password: e.target.value})}
                      InputProps={{
                        endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisucadpass(!visucadpass)}>{!visucadpass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                      }}
                    />
                     <TextField 
                      id="outlined-basic-senha-conf" 
                      label="Confirmar senha" 
                      variant="outlined" 
                      margin="dense" 
                      type={visuconfpass? "text":"password"}
                      required
                      error={(dadosCadastrar.password.length>0 && dadosCadastrar.password_confirmation.length>0 && dadosCadastrar.password!=dadosCadastrar.password_confirmation)? true:false}
                      helperText={(dadosCadastrar.password.length>0 && dadosCadastrar.password_confirmation.length>0 && dadosCadastrar.password!=dadosCadastrar.password_confirmation)? <small>Senhas não correspondem.</small>:''}
                      value={dadosCadastrar.password_confirmation}
                      onChange={e=>setDadosCadastrar({...dadosCadastrar, password_confirmation: e.target.value})}
                      // InputProps={{
                      //   endAdornment: <IconButton style={{marginRight:'-0.9rem'}} onClick={e=> setVisuconfpass(!visuconfpass)}>{!visuconfpass? <VisibilityOff fontSize='small'/>: <Visibility fontSize='small'/>}</IconButton>,
                      // }}
                    />
                    <div style={{height:'0.2rem'}}/>
                     <label className={classes.separador}>Informações Complementares</label>
                     <Divider/>
                      <TextField 
                        id="outlined-basic-empresa" 
                        label="Empresa" 
                        variant="outlined" 
                        margin="dense" 
                        type="text"
                        //required
                        value={dadosCadastrar.organization}
                        onChange={e=>setDadosCadastrar({...dadosCadastrar, organization: e.target.value})}
                      />
                      <TextField 
                        id="outlined-basic-telefone" 
                        label="Telefone" 
                        variant="outlined" 
                        margin="dense" 
                        type="text"
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        //required
                        value={dadosCadastrar.number}
                        onChange={e=>setDadosCadastrar({...dadosCadastrar, number: e.target.value})}
                      />
                       <TextField 
                        id="outlined-basic-cidade" 
                        label="Cidade" 
                        variant="outlined" 
                        margin="dense" 
                        type="text"
                        //required
                        value={dadosCadastrar.city}
                        onChange={e=>setDadosCadastrar({...dadosCadastrar, city: e.target.value})}
                      />
                       <TextField 
                        id="outlined-basic-estado" 
                        label="Estado" 
                        variant="outlined" 
                        margin="dense" 
                        type="text"
                        //required
                        value={dadosCadastrar.state}
                        onChange={e=>setDadosCadastrar({...dadosCadastrar, state: e.target.value})}
                      />
                       <FormControlLabel
                        control={
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={dadosCadastrar.accept_terms}
                            onChange={e=>setDadosCadastrar({...dadosCadastrar, accept_terms: e.target.checked})}
                            name="checkedB"
                            color="primary"
                            required
                          />
                        }
                        label={<small>Declaro que li e concordo com os <a href={`/termos`} target="_blank">termos de uso</a>.*</small>}
                      />
                    <div style={{height:'0.2rem'}}/>
                 <Button variant="contained" color="secondary" style={{margin:'auto'}} type='submit'>Cadastrar</Button>
                 <small>* Campos obrigatórios.</small>
             </form>
             <div className={classes.infoCadastro}>
               <p>Vantagens de ser cadastrado:</p>
               <ul>
                 <li>Acesso total ao conteúdo do site de Ferramentas;</li>
                 <li>Download ilimitado dos arquivos convertidos;</li>
                 <li>Visualização ilimitada de tamanho dos cabos;</li>
                 <li>Acesso exclusivo a atualizações.</li>
               </ul>
               <h5>Já é cadastrado?</h5>
               <Button variant="contained" color='primary' style={{margin:'0rem 1.5rem'}} onClick={()=>setPagina('login')}>Fazer Login</Button>
               <img className={classes.imagemCad} src='../files/images/cadastrologin/image001.png' />
               <p>Realize seu cadastro para ter acesso a todas as ferramentas da plataforma de forma <b>gratuita</b>. </p>
             </div>
          </div>
           </DialogContentText>
         </DialogContent>
         </>:''}
         {pagina=='recuperar-senha'?
         <>
         <DialogTitle id="scroll-dialog-title" >Recupere sua senha</DialogTitle>
         <DialogContent >
           <DialogContentText
             id="scroll-dialog-description"
             ref={descriptionElementRef}
             tabIndex={-1}
           >
          
          <form onSubmit={novoEsqueciasenha}>
            <div style={{display:'flex', flexDirection:'column'}}>
             {dadosRecuperar.status?
              <>
              <Alert severity={'success'}>
                {/* <strong>E-mail para redefinição de senha enviado com sucesso!</strong><br/> */}
                {/* Após enviada a solicitação um link de verificação será enviado por e-mail, verifique sua caixa de SPAM e lixo eletrônico. */}
                Um link de recuperação foi enviado por e-mail, verifique sua caixa de SPAM e lixo eletrônico.
              </Alert>
              <br/>
              <Button variant="contained" color='primary' style={{margin:'0rem 1.5rem'}} disabled >Enviado</Button>
              </>
            :
            <>
             <label>Digite seu e-mail.</label>
              <TextField 
                  id="outlined-basic-email" 
                  label="E-mail" 
                  variant="outlined" 
                  margin="dense" 
                  type="email"
                  required
                  value={dadosRecuperar.email}
                  onChange={e=>setDadosRecuperar({...dadosRecuperar, email: e.target.value})}
                  style={{width:'95%', margin:'auto'}}
                  helperText={'Um link de recuperação será enviado por e-mail, verifique sua caixa de SPAM e lixo eletrônico.'}
                />
                <br/>
                <Button variant="contained" color='primary' style={{margin:'0rem 1.5rem'}} type={'submit'} >Enviar</Button>
                
            </>}
             <br/>
                <Divider/>
                <br/>
                <div style={{margin:'auto', textAlign:'center', fontSize:'14px'}}>
                    Deseja sair desta página? <br/>
                    <label style={{textDecoration:'underline ', cursor:'pointer'}} onClick={()=>{setPagina('login');setDadosRecuperar({email:'',status:false})}}>Retorne para a sessão de login.</label>
                </div>
            </div>
          </form>
          
           </DialogContentText>
         </DialogContent>
         </>:''}
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions> */}
          <Snackbar open={open2.status} autoHideDuration={6000} onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}}>
            <Alert onClose={(event,reason)=>{  if (reason === 'clickaway') {return;}; setOpen2({status:false, type:'', message:''});}} severity={open2.type}>
              {open2.message}
            </Alert>
          </Snackbar>
          {showBackdrop? <Backdrop/>:''}
      </Dialog>
    </div>
  );
}
